/**
 * When you add a new language, add the country and the language here
 * The countrycode refers to the name of the countries' SVG flag (in public/flags) (see the legend in ./FlagLanguageLegend.txt)
 */

const languageList = [
  { countrycode: 'FR', languagecode: 'fr-FR' },
  { countrycode: 'GB', languagecode: 'en-GB' },
  { countrycode: 'US', languagecode: 'en-US' },
  { countrycode: 'DE', languagecode: 'de-DE' },
];
const getTranslationFromLang = (intl, languageCode) => {
  switch (languageCode) {
    case 'fr-FR':
      return intl.formatMessage({ id: 'language.fr-FR', defaultMessage: 'French' });
    case 'de-DE':
      return intl.formatMessage({ id: 'language.de-DE', defaultMessage: 'German' });
    case 'en-GB':
    case 'en-US':
    default:
      return intl.formatMessage({ id: 'language.en-GB', defaultMessage: 'English' });
  }
};
const getLanguageCodeFromNavigator = languageCode => {
  let finalLanguageCode = null;
  switch (languageCode) {
    case 'fr-FR':
    case 'FR':
    case 'fr':
      finalLanguageCode = 'fr-FR';
      break;
    case 'de-DE':
    case 'DE':
    case 'de':
      finalLanguageCode = 'de-DE';
      break;
    case 'en-GB':
    case 'EN':
    case 'en':
      finalLanguageCode = 'en-GB';
      break;
    case 'en-US':
      finalLanguageCode = 'en-US';
      break;
    default:
      finalLanguageCode = 'en-GB';
  }
  return finalLanguageCode;
};
const getContrycodeFromLanguage = languageCode => {
  let finalContrycode = null;
  switch (languageCode) {
    case 'fr-FR':
    case 'FR':
    case 'fr':
      finalContrycode = 'fr';
      break;
    case 'de-DE':
    case 'DE':
    case 'de':
      finalContrycode = 'de';
      break;
    case 'en-GB':
    case 'EN':
    case 'en':
      finalContrycode = 'gb';
      break;
    case 'en-US':
      finalContrycode = 'us';
      break;
    default:
      finalContrycode = 'gb';
  }
  return finalContrycode;
};

export { languageList, getTranslationFromLang, getLanguageCodeFromNavigator, getContrycodeFromLanguage };
