import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import React, { lazy, memo } from 'react';
import { useRoutes } from 'react-router-dom';

import '@assets/style/consignity/projet.scss';
import '@assets/style/consignity/themes/themes.scss';
import '@assets/style/general.scss';
import TopLevelErrorBoundary from '@boundaries/TopLevelErrorBoundary.jsx';
import { AuthContextProvider } from '@contexts/AuthContext.jsx';
import { LanguageContextWrapper } from '@contexts/LanguageContext.jsx';
import PrivatePage from '@views/PrivatePage.jsx';
import PublicPage from '@views/PublicPage.jsx';

const NodesMainPage = lazy(() => import('@views/nodes/NodesLayout.jsx'));
const DashboardsMainPage = lazy(() => import('@views/dashboards/DashboardLayout.jsx'));
const ManagementMainPage = lazy(() => import('@views/management/ManagementLayout.jsx'));
const SuperAdminMainPage = lazy(() => import('@views/admin/AdminLayout.jsx'));

const SignIn = lazy(() => import('@views/auth/SignIn.jsx'));
const SignOut = lazy(() => import('@views/auth/SignOut.jsx'));
const Register = lazy(() => import('@views/auth/Register.jsx'));
const ResetPassword = lazy(() => import('@views/auth/ResetPassword.jsx'));
const NewPassword = lazy(() => import('@views/auth/NewPassword.jsx'));
const ResendVerification = lazy(() => import('@views/auth/ResendVerification.jsx'));
const ConfirmMail = lazy(() => import('@views/auth/ConfirmMail.jsx'));
const NotFoundPage = lazy(() => import('@views/NotFoundPage.jsx'));

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const App = memo(() => {
  const routes = useRoutes([
    {
      path: '/',
      element: <PrivatePage element={<NodesMainPage />} />,
      exact: true,
    },
    {
      path: '/object',
      element: <PrivatePage element={<NodesMainPage />} />,
      exact: true,
    },
    {
      path: '/object/:id',
      element: <PrivatePage element={<NodesMainPage />} />,
      exact: false,
    },
    {
      path: '/object/:id/:tab',
      element: <PrivatePage element={<NodesMainPage />} />,
      exact: false,
    },
    {
      path: '/object/:id/:tab/:subTab',
      element: <PrivatePage element={<NodesMainPage />} />,
      exact: false,
    },
    {
      path: '/dashboard',
      element: <PrivatePage element={<DashboardsMainPage />} />,
      exact: true,
    },
    {
      path: '/dashboard/:id',
      element: <PrivatePage element={<DashboardsMainPage />} />,
      exact: false,
    },
    {
      path: '/roles/',
      element: <PrivatePage element={<ManagementMainPage />} />,
      exact: true,
    },
    {
      path: '/roles/:category',
      element: <PrivatePage element={<ManagementMainPage />} />,
      exact: true,
    },
    {
      path: '/roles/:category/:categoryId',
      element: <PrivatePage element={<ManagementMainPage />} />,
      exact: false,
    },
    {
      path: '/admin/',
      element: <PrivatePage element={<SuperAdminMainPage />} />,
      exact: true,
    },

    { path: '/signin', element: <PublicPage element={<SignIn />} />, exact: true },
    { path: '/signout', element: <PublicPage element={<SignOut />} />, exact: true },
    { path: '/register', element: <PublicPage element={<Register />} />, exact: true },
    { path: '/reset', element: <PublicPage element={<ResetPassword />} />, exact: true },
    { path: '/new-password/:token', element: <PublicPage element={<NewPassword />} />, exact: true },
    { path: '/resend-verification', element: <PublicPage element={<ResendVerification />} />, exact: true },
    { path: '/confirmation/:token', element: <PublicPage element={<ConfirmMail />} />, exact: true },

    { path: '*', element: <PublicPage element={<NotFoundPage />} />, exact: true },
  ]);

  return (
    <TopLevelErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <AuthContextProvider>
          <LanguageContextWrapper>{routes}</LanguageContextWrapper>
        </AuthContextProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </TopLevelErrorBoundary>
  );
});

export default App;
