import { useCallback, useEffect, useState } from 'react';

const PREFIX = 'CONSIGNITY-';

export default function useLocalStorage(key, defaultValue) {
  const prefixedKey = PREFIX + key;
  const [value, setValue] = useState(() => {
    const jsonValue = localStorage.getItem(prefixedKey);
    if (jsonValue && jsonValue !== 'undefined') {
      return JSON.parse(jsonValue);
    }
    return defaultValue;
  });

  useEffect(() => {
    try {
      localStorage.setItem(prefixedKey, JSON.stringify(value));
    } catch (e) {
      console.error('ERROR useLocalStorage', e, JSON.stringify(value));
    }
  }, [prefixedKey, value]);

  function getKey() {
    return prefixedKey;
  }

  return [value, setValue, getKey];
}
