import { createRequest } from './request.js';
import routes from './routes.json';

export const loginCredentials = createRequest({ method: 'POST', url: routes.auth.users.signinWithLogin });
export const loginToken = createRequest({ method: 'POST', url: routes.auth.users.signinWithToken });
export const impersonate = createRequest({ method: 'POST', url: routes.auth.users.impersonate });
export const resetPassword = createRequest({ method: 'POST', url: routes.auth.users.resetPassword });
export const setNewPassword = createRequest({ method: 'POST', url: routes.auth.users.setNewPassword });
export const resendVerification = createRequest({ method: 'POST', url: routes.auth.users.resendVerification });
export const signup = createRequest({ method: 'POST', url: routes.auth.users.signup });
export const signout = createRequest({ method: 'POST', url: routes.auth.users.signout });
export const activateUser = createRequest({ method: 'PATCH', url: routes.auth.users.confirmAccount });
