import PropTypes from 'prop-types';
import React, { memo, Suspense } from 'react';

import Loading from './Loading.jsx';

const PublicPage = memo(({ element }) => <Suspense fallback={<Loading />}>{element}</Suspense>);

PublicPage.propTypes = { element: PropTypes.object.isRequired };

export default PublicPage;
