const dictionary = {
  resident: {
    'fr-FR': 'Résident',
    'en-GB': 'Resident',
    'de-DE': 'Bewohner',
    hu: 'Lakó',
    nl: 'Bewoner',
  },
  residents: {
    'fr-FR': 'Résidents',
    'en-GB': 'Residents',
    'de-DE': 'Bewohner',
    hu: 'Lakósok',
    nl: 'Bewoners',
  },
  carrier: {
    'fr-FR': 'Transporteur',
    'en-GB': 'Carrier',
    'de-DE': 'Träger',
    hu: 'Hordozó',
    nl: 'Transporteur',
  },
  carriers: {
    'fr-FR': 'Transporteurs',
    'en-GB': 'Carriers',
    'de-DE': 'Spediteur',
    hu: 'Szállítók',
    nl: 'Transporteurs',
  },
  service: {
    'fr-FR': 'Service',
    'en-GB': 'Service',
    'de-DE': 'Dienst',
    hu: 'Szolgáltatás',
    nl: 'Dienstverlener',
  },
  services: {
    'fr-FR': 'Services',
    'en-GB': 'Services',
    'de-DE': 'Dienst',
    hu: 'Szolgáltatások',
    nl: 'Dienstverleners',
  },
};

export default dictionary;
