import { createRequest } from './request.js';
import routes from './routes.json';

export const getSystemPermissions = createRequest({ method: 'GET', url: routes.management.permissions });

export const getUser = createRequest({ method: 'GET', url: routes.management.users.user });
export const getUsers = createRequest({ method: 'GET', url: routes.management.users.users });
export const getGroup = createRequest({ method: 'GET', url: routes.management.groups.group });
export const getGroups = createRequest({ method: 'GET', url: routes.management.groups.groups });
export const getAccessType = createRequest({ method: 'GET', url: routes.management.accessType.accessType });
export const getAccessTypes = createRequest({ method: 'GET', url: routes.management.accessType.accessTypes });
export const getPermissions = createRequest({ method: 'GET', url: routes.management.permissions });

export const createUser = createRequest({ method: 'POST', url: routes.management.users.users });
export const createGroup = createRequest({ method: 'POST', url: routes.management.groups.groups });
export const createAccessType = createRequest({ method: 'POST', url: routes.management.accessType.accessTypes });

export const updateUser = createRequest({ method: 'PATCH', url: routes.management.users.user });
export const resendVerificationMail = createRequest({ method: 'POST', url: routes.management.users.resendVerification });
export const resendPasswordReset = createRequest({ method: 'POST', url: routes.management.users.resetPasswordReset });
export const patchGroup = createRequest({ method: 'PATCH', url: routes.management.groups.group });
export const patchAccessType = createRequest({ method: 'PATCH', url: routes.management.accessType.accessType });

export const deleteUser = createRequest({ method: 'DELETE', url: routes.management.users.user });
export const deleteGroup = createRequest({ method: 'DELETE', url: routes.management.groups.group });
export const deleteAccessType = createRequest({ method: 'DELETE', url: routes.management.accessType.accessType });
