/* eslint-disable no-underscore-dangle */
import Spin from 'antd/es/spin';
import axios from 'axios';
import React, { createContext, useCallback, useEffect, useState } from 'react';

import { connectSocket, disconnectSocket } from '@/socket.js';
import { loginCredentials, loginToken, impersonate as impersonateRequest } from '@api/auth.js';
import { getUser } from '@api/management.js';
import request from '@api/request.js';
import useLocalStorage from '@hooks/useLocalStorage.js';
import useSubscription from '@hooks/useSubscription.js';

const AuthContext = createContext({});

const updateTheme = theme => {
  if (theme) {
    const { body } = document;
    body.className = '';
    body.classList.add(`theme-${theme}`);
    body.classList.add('theme-changing');
    setTimeout(() => {
      body.classList.remove('theme-changing');
    }, 100);
  }
};

// eslint-disable-next-line react/prop-types
export const AuthContextProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState();
  const [, setAxiosInterceptor] = useState(null);
  const [, setRequestInterceptor] = useState(null);
  const [preferences, setPreferences, getKey] = useLocalStorage('preferences', {});

  const _updateInterceptors = useCallback(token => {
    if (!token) {
      return;
    }
    setRequestInterceptor(currInterceptor => {
      if (currInterceptor) {
        request.interceptors.request.eject(currInterceptor);
      }
      const newInterceptor = request.interceptors.request.use(config => {
        config.headers.authorization = `Bearer ${token}`;
        return config;
      });
      return newInterceptor;
    });
    setAxiosInterceptor(currInterceptor => {
      if (currInterceptor) {
        axios.interceptors.request.eject(currInterceptor);
      }
      const newInterceptor = axios.interceptors.request.use(config => {
        config.headers.authorization = `Bearer ${token}`;
        return config;
      });
      return newInterceptor;
    });
    connectSocket(token);
  }, []);

  const updateUser = useCallback(value => {
    setUser(prev => (prev ? { ...prev, ...value } : null));
  }, []);

  async function login(username, password) {
    setLoading(true);
    const response = await loginCredentials({ body: { login: username, password } });
    setLoading(false);
    if (response.success) {
      localStorage.setItem('token', response.data.token);
      setUser(response.data);
      _updateInterceptors(response.data.token);
      updateTheme(response.data?.profile?.theme);
      return { success: true, error: null };
    }
    return { success: false, error: response.error };
  }

  const signBack = useCallback(
    async token => {
      setLoading(true);
      const response = await loginToken({ body: { token } });
      setLoading(false);
      if (response.success) {
        localStorage.setItem('token', response.data.token);
        setUser(response.data);
        _updateInterceptors(response.data.token);
        updateTheme(response.data?.profile?.theme);
      } else {
        localStorage.removeItem('token');
        setUser(null);
      }
    },
    [_updateInterceptors],
  );

  const logout = useCallback(async () => {
    setLoading(true);
    const token = localStorage.getItem('real_token');
    if (token) {
      localStorage.removeItem('real_token');
      localStorage.setItem(getKey(), localStorage.getItem(`real_${getKey()}`));

      await signBack(token);
      return false;
    }
    localStorage.removeItem('token');
    localStorage.removeItem(getKey());
    setUser(null);
    setRequestInterceptor(current => {
      request.interceptors.request.eject(current);
      return null;
    });
    setAxiosInterceptor(current => {
      axios.interceptors.request.eject(current);
      return null;
    });
    updateTheme('light');
    disconnectSocket();
    setLoading(false);
    return true;
  }, [signBack]);

  async function impersonate(userId) {
    setLoading(true);
    const response = await impersonateRequest({ params: { userId } });
    setLoading(false);
    if (response.success) {
      localStorage.setItem('real_token', localStorage.getItem('token'));
      localStorage.setItem(`real_${getKey()}`, localStorage.getItem(getKey()));

      setUser(response.data);
      localStorage.setItem('token', response.data.token);
      _updateInterceptors(response.data.token, response.data.id);
      updateTheme(response.data?.profile?.theme);
    }
    return response.success;
  }

  // Update user on socket event
  useSubscription({
    channels: ['management/user'],
    events: ['management.user.updated'],
    callback: async event => {
      if (event.id === user?.id) {
        const response = await getUser({ params: { userId: user.id } });
        if (response.success) {
          setUser(response.data);
        } else {
          console.error('Cannot fetch current user: %s', response.error);
        }
      }
    },
  });

  // Auto-login
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      signBack(token);
    } else {
      // updateTheme('light')
      setLoading(false);
    }
  }, [signBack]);

  const values = {
    user,
    isAuthenticated: Boolean(user),
    isImpersonate: Boolean(localStorage.getItem('real_token')),
    globalPermissions: user?.access?.general || {},
    login,
    logout,
    updateUser,
    impersonate,
    preferences,
    setPreferences,
  };

  return <AuthContext.Provider value={values}>{loading ? <Spin /> : children}</AuthContext.Provider>;
};

export default AuthContext;
