import axios from 'axios';

const request = axios.create({
  baseURL: process.env.REACT_APP_CORELAYER_API,
  timeout: 30000,
  validateStatus: code => code >= 200 && code < 300,
});

export default request;

/**
 * Create a request handler.
 *
 * @param {object} options Factory options
 * @param {('GET'|'PATCH'|'POST'|'PUT'|'DELETE')} options.method HTTP VERB
 * @param {string} options.url URL
 * @returns {requestCallback} Request function
 */
export function createRequest({ method, url: rawUrl }) {
  /**
   * Request handler.
   *
   * @callback requestCallback
   * @param {object} options Request options
   * @param {object} [options.body] Request body
   * @param {object} [options.params] Request parameters
   * @param {object} [options.query] Request query
   * @param {object} [options.headers] Request headers
   * @returns {Promise<{
   *  success: boolean,
   *  code: number,
   *  status: string,
   *  data: object,
   *  error: ?string,
   * }>} Response object
   */
  return async (options = {}) => {
    const { body = {}, params = {}, query = null, headers = null, abortSignal } = options;
    const result = {
      success: true,
      code: null,
      status: null,
      data: {},
      error: null,
    };
    let url = Object.keys(params).reduce((u, paramName) => u.replaceAll(`:${paramName}`, params[paramName]), rawUrl);
    if (query) {
      const urlParams = new URLSearchParams(query);
      url += `?${urlParams.toString()}`;
    }
    try {
      const response = await request({
        method,
        url,
        data: body,
        signal: abortSignal,
      });
      result.code = response.status;
      result.status = response.statusText;
      result.data = response.data;
    } catch (error) {
      console.error(error);
      result.success = false;
      if (error.response) {
        result.code = error.response.status;
        result.status = error.response.statusText;
        result.error = error.response.data?.error ?? error.message;
        result.data = error.response.data;
      } else {
        result.code = 500;
        result.status = 'Server Down';
        result.error = 'Server Down';
      }
    }
    return result;
  };
}

export function createRequestFile({ method, url: rawUrl }) {
  /**
   * Request handler.
   *
   * @callback requestCallback
   * @param {object} options Request options
   * @param {object} [options.body] Request body
   * @param {object} [options.params] Request parameters
   * @param {object} [options.query] Request query
   * @returns {Promise<{
   *  success: boolean,
   *  code: number,
   *  status: string,
   *  data: object,
   *  error: ?string,
   * }>} Response object
   */
  return async (options = {}) => {
    const { body = {}, params = {}, query = null, abortSignal } = options;
    const result = {
      success: true,
      code: null,
      status: null,
      data: {},
      error: null,
    };
    let url = Object.keys(params).reduce((u, paramName) => u.replaceAll(`:${paramName}`, params[paramName]), rawUrl);
    if (query) {
      const urlParams = new URLSearchParams(query);
      url += `?${urlParams.toString()}`;
    }
    try {
      const response = await request({
        method,
        url,
        data: body,
        signal: abortSignal,
      });
      result.code = response.status;
      result.status = response.statusText;
      result.data = response.data;
    } catch (error) {
      console.error(error);
      result.success = false;
      if (error.response) {
        result.code = error.response.status;
        result.status = error.response.statusText;
        result.error = error.response.data?.error ?? error.message;
      } else {
        result.code = 500;
        result.status = 'Server Down';
        result.error = 'Server Down';
      }
    }
    return result;
  };
}
