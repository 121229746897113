import PropTypes from 'prop-types';
import React, { Suspense, useContext } from 'react';
import { Navigate } from 'react-router-dom';

import AuthContext from '@contexts/AuthContext.jsx';

import Loading from './Loading.jsx';

const PrivatePage = ({ element }) => {
  const { user } = useContext(AuthContext);

  if (user) {
    return <Suspense fallback={<Loading />}>{element}</Suspense>;
  }
  return <Navigate to="/signin" />;
};

PrivatePage.propTypes = { element: PropTypes.object.isRequired };

export default PrivatePage;
