import { useCallback, useEffect } from 'react';

import socket from '../socket.js';

/**
 * Setup subscription.
 *
 * @param {string[]} [channels] Channels to join
 * @param {string[]} [events] Events to listen
 * @param {(data: { id: string, data: object }) => void} callback Callback
 */
export function setup(channels, events, callback) {
  channels.forEach(channel => socket.emit('subscribe', channel));
  events.forEach(event => socket.on(event, callback));
}

/**
 * Cleanup subscription.
 *
 * @param {string[]} [channels] Channels to join
 * @param {string[]} [events] Events to listen
 * @param {(data: { id: string, data: object }) => void} callback Callback
 */
export function cleanup(channels, events, callback) {
  channels.forEach(channel => socket.emit('unsubscribe', channel));
  events.forEach(event => socket.off(event, callback));
}

/**
 * Subscribe to event, and join optionnal channels
 *
 * Enabled by default.
 *
 * Please consider callback with {@link react.useCallback}
 *
 * @example ```javascript
 * const subscription = useMemo(() => ({
 *  enabled: runMode,
 *  events: ['node.history.updated'],
 *  channels: ['node/nodeId/history'],
 *  callback: updateHistory,
 * }), [runMode, updateHistory])
 * useSubscription(subscription)
 * ```
 *
 * @param {object} options Hook options
 * @param {boolean} [options.enabled] Enable subscription, default value to true
 * @param {string[]} [options.events] Events to listen
 * @param {string[]} [options.channels] Channels to join
 * @param {(data: { id: string, data: object }) => void} options.callback Callback
 */
export default function useSubscription({ enabled = true, events = [], channels = [], callback }) {
  const reconnectCallback = useCallback(() => {
    setTimeout(() => {
      cleanup(channels, events, callback);
      setup(channels, events, callback);
    }, 100);
  }, [channels, events, callback]);

  useEffect(() => {
    if (!enabled) { return () => {}; }
    socket.on('connect', reconnectCallback);
    setup(channels, events, callback);

    return () => {
      socket.off('connect', reconnectCallback);
      cleanup(channels, events, callback);
    };
  }, [enabled, events, channels, callback, reconnectCallback]);
}
